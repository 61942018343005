.task-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  padding: 50px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.task-container::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

.task-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.task-container::-webkit-scrollbar-thumb {
  background-color: rgb(67, 67, 67);
  outline: 1px solid #333333;
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.task-header h2 {
  font-size: 24px;
  color: #333;
}

.task-add-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.task-add-btn:hover {
  background-color: #0056b3;
}

.task-categories {
  display: flex;

  flex-wrap: nowrap;
  padding-top: 20px;
  border-radius: 4px;

  white-space: nowrap;
  padding: 0 0 10px 0;
}

.task-categories > div {
  flex: 0 0 300px;
  display: inline-block;
  width: 300px;
  height: 100%;
  margin-right: 15px;
  padding: 15px;
  .title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .item {
    margin: 10px 0px 5px 0px;

    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    .org{
      white-space: break-spaces;
    }

    .item-title {
      font-weight: 600;
      color: #5a6268;
      overflow: hidden;
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      span {
      }
      svg {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: white;
        padding: 10px;
      }
    }
    .span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      margin-right: 10px;
      line-height: 1.5rem;
      white-space: pre-wrap;
      max-height: 4.5rem;
      overflow: auto;
    }
    span::-webkit-scrollbar {
      width: 0.1rem;
      height: 0.6rem;
    }

    span::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    span::-webkit-scrollbar-thumb {
      background-color: rgb(67, 67, 67);
      outline: 1px solid #333333;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.popup-back {
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}
.popup-inner {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.form-actions {
  text-align: right;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 500px;
  max-width: 100%;

  z-index: 3;
  button {
    border: none;
    font-weight: 600;
    margin-left: 0.5rem;
    /* background: transparent; */
    font-size: 0.9rem;
  }
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

@media (max-width: 768px) {
  .popup-inner {
    top: 100px;
    border-radius: 10px 10px 0px 0px;
  }
  .task-categories > div {
    flex: 0 0 250px;
    display: inline-block;
    width: 250px;
    height: 100%;
    margin-right: 15px;
    padding: 15px;
    .title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .item {
      margin: 10px 0px 5px 0px;
      padding: 8px 12px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      font-size: 0.8rem;
    }
  }
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(154, 152, 152, 0.5);
  z-index: 3;
}
.sidebar-inner {
  width: 200px;
  background-color: #343a40;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  transition: transform 0.3s ease;
}
.log-out {
  position: fixed;
  bottom: 5px;
  left: 105px;
}
.sidebar-toggle {
  position: fixed;
  background-color: #343a40;
  color: white;
  border: none;
  padding: 6px;
  cursor: pointer;
  border-radius: 2px;
  z-index: 1000;
  top: 8px;
}

.sidebar-toggle:hover {
  background-color: #495057;
}
