.main .container{
    max-width: 400px;
    background-color: white;
    padding: 30px;
}
.head{
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
}
input{
   outline: none;
}
.gradient-btn {
    background: linear-gradient(to right, #58B0DE, #B734DA);
    border: none; 
    color: white; 
    width: 100%;
}
.main{
    height: 100vh;
    background: linear-gradient(to right, #58B0DE, #B734DA);
    padding-top: 100px;
}
.link{
    text-decoration: none;
    color: black;
    font-weight: 600;
}
.bottom{
    padding: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}